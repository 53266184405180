import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M3865 8924 c-16 -2 -95 -11 -175 -20 -278 -30 -550 -90 -845 -188
-161 -54 -420 -162 -437 -184 -11 -14 -10 -15 7 -8 52 19 384 56 513 56 40 0
62 -4 62 -11 0 -13 -46 -59 -59 -59 -9 0 -51 -43 -51 -52 0 -4 14 -9 30 -13
17 -4 30 -10 30 -13 0 -4 69 -7 152 -7 180 0 493 -13 628 -26 168 -16 194 -20
260 -34 36 -8 85 -17 110 -21 25 -4 52 -8 60 -10 8 -1 31 -7 50 -13 19 -6 103
-27 185 -47 83 -19 164 -41 180 -48 17 -8 46 -17 65 -21 42 -8 98 -25 125 -37
12 -5 28 -10 38 -12 9 -1 20 -5 23 -9 4 -4 17 -7 29 -7 12 0 25 -3 29 -7 3 -4
12 -8 19 -9 17 -3 138 -43 162 -54 11 -5 45 -19 75 -31 50 -19 90 -37 200 -88
19 -9 37 -19 38 -23 2 -5 8 -8 13 -8 6 0 38 -13 72 -29 34 -17 85 -39 112 -49
28 -11 79 -34 114 -51 36 -17 70 -31 78 -31 7 0 13 -4 13 -9 0 -5 16 -15 35
-21 19 -6 35 -16 35 -21 0 -5 11 -9 24 -9 13 0 26 -3 28 -7 4 -10 81 -63 91
-63 3 0 48 -25 99 -56 51 -31 119 -70 151 -88 32 -17 66 -38 75 -46 9 -9 33
-26 52 -40 19 -13 37 -27 40 -30 3 -4 41 -31 85 -60 92 -62 164 -118 251 -195
89 -79 334 -321 334 -330 0 -4 9 -17 20 -29 20 -20 96 -110 133 -155 9 -11 17
-23 17 -26 0 -2 12 -21 26 -42 46 -67 113 -213 143 -312 57 -190 66 -263 66
-536 -1 -236 -2 -257 -29 -375 -61 -273 -156 -490 -324 -739 -57 -86 -117
-172 -132 -191 -47 -62 -43 -67 10 -10 104 112 278 360 348 495 119 233 189
473 213 736 15 167 2 362 -36 518 -19 79 -23 107 -14 117 8 10 8 14 0 14 -6 0
-11 5 -11 10 0 6 12 10 26 10 52 0 113 -23 149 -56 21 -19 40 -34 42 -34 14 0
127 -133 174 -205 35 -55 74 -128 71 -137 -1 -5 2 -8 7 -8 16 0 44 -104 57
-210 22 -180 3 -283 -89 -485 -193 -425 -549 -810 -1005 -1087 -370 -225 -762
-335 -1295 -364 -122 -6 -128 -8 -110 -24 29 -28 113 -67 183 -85 62 -15 92
-22 205 -44 28 -5 57 -12 65 -15 17 -6 118 -13 300 -21 69 -3 136 -8 148 -11
13 -3 29 -1 35 4 13 10 107 23 207 28 74 3 190 28 231 50 15 8 48 14 73 14 25
0 47 4 50 9 6 8 21 12 111 27 22 4 51 15 64 25 13 11 29 19 36 19 7 0 38 13
69 29 31 17 56 28 56 25 0 -3 10 2 22 10 12 9 30 16 39 16 10 0 19 3 21 8 5
11 69 56 98 69 28 12 68 43 111 86 16 16 29 23 31 16 4 -12 58 35 58 50 0 5
14 11 31 15 18 3 42 16 54 29 39 40 90 81 98 79 4 -1 12 9 17 23 5 14 16 25
24 25 9 0 22 14 31 30 9 17 20 30 25 30 13 0 80 66 80 79 0 6 6 11 13 11 7 0
20 9 30 20 9 11 12 20 7 20 -18 0 -10 22 10 27 11 3 31 25 45 49 14 25 30 42
36 39 5 -4 9 3 9 14 0 11 11 32 24 48 13 15 27 47 31 70 4 25 11 40 18 38 7
-3 23 20 37 51 14 31 33 62 43 69 11 7 21 31 24 54 3 23 10 41 14 41 5 0 9 10
9 23 0 13 5 28 11 34 10 10 41 129 41 163 1 8 9 26 18 40 18 27 27 62 36 150
4 30 11 61 16 68 7 8 7 16 -1 26 -8 10 -9 21 -1 41 13 33 13 555 0 570 -4 6
-10 37 -13 70 -8 99 -45 320 -63 371 -9 27 -17 57 -19 69 -4 31 -17 79 -25 90
-5 8 -30 82 -35 105 0 3 -11 27 -23 54 -12 26 -28 69 -36 95 -13 44 -54 131
-67 141 -3 3 -13 19 -22 37 -9 17 -23 38 -31 45 -8 8 -26 38 -41 66 -15 29
-31 49 -36 46 -5 -3 -6 1 -4 9 6 15 -19 70 -28 61 -3 -3 -8 4 -12 15 -3 12
-11 21 -16 21 -5 0 -9 7 -9 15 0 8 -4 15 -9 15 -5 0 -21 21 -36 48 -67 116
-294 394 -430 527 -144 140 -281 262 -345 305 -19 13 -66 47 -105 76 -209 157
-699 412 -921 479 -59 18 -57 18 -49 5 3 -5 16 -10 28 -11 21 0 21 -1 2 -9
-14 -6 -15 -9 -5 -10 9 0 4 -7 -12 -16 -16 -8 -28 -21 -28 -28 0 -16 -22 -26
-59 -26 -14 0 -34 -9 -44 -20 -14 -16 -21 -18 -36 -10 -25 13 -81 13 -81 0 0
-5 6 -10 13 -11 6 0 3 -5 -8 -10 -17 -8 -16 -9 7 -6 15 2 33 0 40 -4 9 -6 8
-9 -7 -10 -11 0 7 -11 40 -24 65 -25 245 -114 232 -115 -4 0 -56 21 -115 46
-338 144 -665 256 -907 309 -33 8 -122 28 -199 45 -164 37 -383 74 -527 90
-57 6 -142 15 -189 21 -91 11 -425 20 -475 13z m2520 -574 c3 -5 2 -10 -4 -10
-5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m64 -36 c14 -12 -19 -1
-35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z m-1084 -164 c3 -6 -1 -7 -9 -4
-18 7 -21 14 -7 14 6 0 13 -4 16 -10z m105 -50 c8 -5 11 -10 5 -10 -5 0 -17 5
-25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z"/>
<path d="M5530 7024 c-446 -213 -1539 -759 -1570 -784 l-25 -20 38 15 c21 9
66 28 100 44 217 99 524 215 625 236 l33 7 -55 -58 c-31 -31 -58 -65 -61 -75
-3 -10 -15 -21 -26 -24 -11 -4 -34 -21 -51 -38 l-30 -32 27 -3 c16 -2 25 -8
22 -16 -4 -10 9 -12 51 -9 32 2 89 5 127 7 39 2 93 9 120 14 110 24 225 32
374 27 86 -3 172 -8 191 -10 19 -3 55 -7 80 -9 51 -4 176 -33 202 -47 10 -5
23 -9 30 -9 11 0 112 -45 168 -75 102 -54 105 -56 139 -85 46 -40 101 -80 109
-80 5 0 14 -11 21 -24 7 -13 30 -36 50 -51 20 -15 48 -46 63 -69 15 -22 43
-59 61 -82 18 -22 42 -69 54 -105 18 -54 20 -73 12 -124 -10 -63 -44 -159 -66
-186 -7 -8 -13 -19 -13 -23 0 -18 -108 -129 -195 -201 -101 -82 -105 -85 -105
-80 0 3 -10 -3 -22 -15 -12 -11 -28 -20 -35 -20 -13 0 -63 -28 -73 -41 -3 -3
-54 -25 -115 -48 -127 -48 -297 -93 -440 -116 -55 -8 -115 -18 -133 -21 -18
-3 -101 -9 -184 -13 -477 -27 -1078 93 -1584 317 -265 117 -533 281 -764 466
-81 66 -346 303 -370 333 -9 10 -40 47 -70 83 -127 150 -283 386 -369 558 -23
45 -43 82 -45 82 -11 0 133 -441 195 -598 108 -273 306 -609 491 -832 112
-136 166 -202 188 -234 14 -19 28 -36 31 -36 3 0 64 -58 135 -128 71 -70 145
-139 164 -152 19 -13 60 -45 90 -71 61 -52 222 -159 239 -159 6 0 11 -4 11
-10 0 -5 15 -17 33 -26 17 -9 48 -29 67 -44 48 -37 253 -140 279 -140 5 0 11
-3 13 -7 5 -12 140 -73 160 -73 7 0 21 -9 32 -19 24 -22 79 -43 281 -108 77
-24 149 -48 160 -53 34 -15 186 -50 219 -50 17 0 56 -7 86 -15 138 -39 337
-67 505 -71 115 -4 179 -10 197 -19 19 -9 37 -11 55 -5 16 5 45 13 65 20 20 6
49 12 65 15 55 7 186 36 204 46 11 5 28 14 39 19 12 6 25 8 30 5 5 -3 22 1 37
8 15 8 60 20 98 27 39 8 89 24 113 37 23 12 64 28 90 34 26 7 55 20 65 30 10
11 22 19 27 19 6 0 19 9 30 20 11 11 28 20 39 20 10 0 24 7 31 15 7 8 19 14
26 13 14 -3 28 7 98 65 19 15 37 27 42 27 9 0 27 16 94 84 21 21 51 47 66 57
51 33 130 118 153 164 13 25 38 66 57 91 43 56 129 231 139 281 4 20 13 50 21
64 19 38 23 344 5 378 -7 12 -10 32 -8 43 13 48 -46 386 -78 451 -8 17 -15 36
-15 44 0 7 -4 13 -10 13 -5 0 -10 7 -10 16 0 8 -11 40 -25 70 -14 30 -25 59
-25 64 0 26 -117 219 -209 345 -110 150 -392 447 -558 586 -29 24 -102 88
-164 142 -61 53 -117 97 -123 97 -6 0 -157 -70 -336 -156z m279 -500 c14 -12
-19 -1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29 -15z m102 -61 c13 -17 13
-17 -6 -3 -11 8 -22 16 -24 17 -2 2 0 3 6 3 6 0 17 -8 24 -17z m193 -142 c88
-81 136 -130 136 -138 0 -4 -37 29 -82 73 -46 45 -111 103 -145 129 -35 26
-63 50 -63 52 0 9 105 -71 154 -116z m176 -183 c15 -21 17 -28 6 -19 -16 13
-41 51 -33 51 2 0 14 -15 27 -32z m-4078 -210 c-17 -17 -15 -33 2 -19 12 10
28 -2 90 -66 42 -43 76 -81 76 -85 0 -11 286 -254 391 -333 146 -109 286 -199
405 -261 101 -53 168 -97 121 -81 -11 4 -16 3 -12 -3 3 -6 1 -10 -4 -10 -6 0
-2 -9 10 -21 16 -16 20 -28 15 -52 -4 -18 -9 -46 -12 -64 -4 -17 -15 -39 -25
-49 -24 -22 -24 -31 2 -39 16 -5 18 -9 8 -21 -6 -8 -20 -12 -30 -9 -9 3 -20 1
-24 -5 -3 -5 -15 -10 -26 -10 -11 0 -22 -6 -25 -14 -3 -8 -12 -12 -20 -9 -9 3
-14 -2 -14 -13 0 -14 -3 -15 -8 -6 -7 9 -15 10 -35 3 -22 -9 -27 -8 -27 5 0 9
-7 14 -17 12 -10 -2 -17 2 -16 8 1 7 -12 19 -30 28 -18 8 -25 16 -17 16 16 0
-36 30 -53 30 -28 0 -287 242 -404 378 -69 80 -169 215 -245 332 -48 74 -178
326 -178 346 0 22 23 12 37 -17 12 -22 19 -13 13 16 -1 6 2 7 5 3 4 -4 17 -1
29 7 25 18 34 19 18 3z m1143 -868 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m40 -8 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m110 -42 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16
-10z"/>
<path d="M4115 2872 c-11 -2 -23 -9 -27 -15 -4 -6 -7 -166 -8 -356 -1 -191 -3
-368 -5 -396 -3 -56 24 -116 76 -171 16 -17 26 -34 22 -38 -5 -4 1 -14 11 -22
19 -13 19 -14 -6 -14 -29 0 -55 -31 -78 -90 -7 -19 -39 -94 -72 -167 l-60
-131 23 -31 c12 -16 35 -35 50 -42 16 -6 29 -15 29 -20 0 -4 17 -11 38 -15 20
-4 42 -11 49 -16 14 -11 160 -2 196 11 45 17 51 35 32 93 -10 29 -21 57 -26
63 -5 5 -9 16 -9 25 0 8 -11 38 -25 65 -13 28 -25 56 -25 63 0 7 -4 11 -9 7
-5 -3 -19 21 -31 52 -12 32 -27 64 -33 72 -9 12 -9 13 0 7 9 -5 11 -2 7 8 -3
9 -9 14 -14 11 -4 -2 -7 9 -8 26 0 19 3 27 10 23 8 -5 9 -1 5 10 -4 9 -3 15 2
11 5 -3 19 8 30 24 18 25 22 49 26 158 6 158 11 190 37 225 17 25 21 26 34 13
9 -8 18 -15 22 -15 11 0 61 -51 137 -138 39 -44 75 -81 81 -81 6 -1 25 -18 43
-40 18 -21 56 -51 84 -67 29 -15 54 -30 57 -34 8 -11 62 -24 93 -22 33 1 56
17 68 49 11 29 15 584 5 643 -5 25 -13 53 -18 63 -5 9 -15 59 -21 110 l-13 92
-71 0 c-40 -1 -74 -3 -77 -5 -2 -3 -7 -108 -11 -235 -7 -268 -9 -279 -33 -266
-21 11 -63 53 -94 93 -27 35 -42 51 -176 195 -56 61 -122 135 -146 165 -43 53
-45 54 -97 54 -30 0 -63 -2 -74 -4z m41 -1233 c3 -6 14 -9 24 -6 16 4 37 -19
27 -29 -8 -9 -118 -25 -127 -19 -15 9 28 65 50 65 10 0 22 -5 26 -11z"/>
<path d="M6382 2867 c-13 -15 -43 -91 -87 -217 -63 -184 -125 -287 -125 -209
0 18 -4 28 -10 24 -5 -3 -10 3 -10 14 0 12 -3 21 -7 21 -5 0 -15 17 -24 38 -9
20 -27 62 -40 92 -13 30 -34 70 -46 89 -13 18 -33 60 -44 92 l-22 59 -98 0
-98 0 -5 -32 c-3 -18 -8 -203 -11 -411 l-6 -377 25 -55 c47 -99 114 -114 154
-35 12 24 31 151 28 187 -1 14 7 18 34 19 37 0 62 -20 52 -45 -3 -9 -1 -12 6
-7 8 5 12 0 12 -13 0 -12 4 -21 9 -21 5 0 15 -15 22 -32 15 -37 68 -99 110
-129 l28 -19 34 30 c19 17 42 47 50 68 8 20 28 50 45 65 27 26 31 27 46 12 9
-9 16 -25 16 -35 0 -10 5 -22 10 -25 6 -4 8 -10 5 -15 -6 -10 93 -114 110
-116 6 -1 23 -4 38 -8 19 -4 26 -3 22 4 -3 5 2 10 12 11 10 0 13 3 6 6 -10 3
-10 10 -2 24 17 32 12 595 -7 714 -16 101 -33 224 -34 238 0 13 -187 8 -198
-6z"/>
<path d="M7069 2873 l-37 -4 -60 -172 c-89 -253 -165 -460 -207 -565 -34 -86
-35 -95 -21 -111 9 -10 16 -26 16 -36 0 -10 16 -38 36 -61 33 -42 38 -44 87
-44 44 0 55 4 72 26 32 43 84 63 188 70 106 8 182 -7 190 -37 3 -10 20 -25 38
-34 18 -10 39 -23 45 -30 21 -21 91 -27 123 -11 34 18 44 56 30 112 -6 21 -13
51 -16 67 -3 15 -9 30 -14 33 -5 3 -9 18 -9 34 0 16 -5 32 -10 35 -6 4 -8 11
-4 16 3 6 -2 16 -12 23 -11 8 -15 21 -11 35 3 11 1 19 -4 15 -5 -3 -9 3 -9 13
0 10 -14 45 -30 78 -16 32 -26 63 -24 67 3 4 1 8 -5 8 -6 0 -8 7 -5 15 4 8 1
15 -5 15 -6 0 -11 7 -11 16 0 19 -41 118 -52 122 -4 2 -4 10 -1 19 5 12 3 14
-7 8 -10 -6 -12 -3 -8 13 4 12 0 26 -8 33 -8 6 -14 17 -14 24 0 8 -5 15 -10
17 -6 2 -28 46 -49 98 l-40 95 -53 1 c-29 1 -69 -1 -89 -3z m84 -530 c-7 -2
-21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M7720 2871 l-55 -6 -5 -420 c-3 -231 -2 -424 3 -430 4 -5 7 -24 7
-42 0 -27 9 -42 46 -78 57 -54 99 -70 140 -55 54 21 64 41 64 130 0 79 11 110
38 110 6 0 29 -37 51 -83 22 -45 54 -94 71 -107 17 -14 36 -30 43 -36 37 -33
147 -19 147 18 0 10 4 18 10 18 5 0 20 12 34 27 22 23 26 25 32 10 3 -9 12
-17 18 -17 7 0 17 -10 23 -23 6 -12 23 -28 39 -36 16 -7 36 -17 44 -22 45 -27
66 -30 104 -19 40 12 69 33 59 43 -3 3 2 13 11 22 10 10 17 35 17 68 8 267 7
302 -15 542 -9 95 -51 216 -93 268 -13 16 -28 48 -34 73 -10 39 -15 44 -46 50
-50 9 -154 -2 -164 -18 -5 -7 -31 -71 -60 -143 -99 -253 -109 -273 -127 -270
-10 1 -16 9 -14 20 2 9 -3 24 -11 33 -8 10 -17 30 -21 45 -4 15 -11 25 -16 22
-6 -4 -14 3 -18 14 -4 11 -11 21 -17 23 -5 2 -9 9 -7 16 2 6 -2 12 -8 12 -5 0
-10 7 -10 15 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 -4 15 -8 15 -5 0 -17 19
-27 43 -10 23 -28 66 -41 95 l-23 52 -53 -2 c-29 -1 -78 -4 -108 -7z"/>
<path d="M2229 2598 c-1 -150 -3 -337 -5 -416 -4 -140 -3 -143 19 -151 12 -5
46 -34 74 -65 88 -92 139 -109 338 -109 108 0 185 13 211 36 19 17 38 31 58
42 10 6 17 14 15 17 -2 4 5 17 17 30 11 13 29 42 39 66 10 23 22 42 27 42 4 0
8 7 8 15 0 8 4 15 9 15 12 0 22 27 28 75 6 53 6 51 -1 120 -3 33 -8 87 -11
120 -11 114 -41 197 -107 293 -31 44 -138 122 -167 122 -10 -1 -5 -7 12 -16
40 -21 33 -24 -17 -6 -25 9 -43 19 -41 23 7 10 -138 19 -327 19 l-178 0 -1
-272z m487 -74 c45 -13 77 -44 95 -90 14 -35 14 -47 3 -79 -7 -21 -23 -52 -35
-69 -19 -26 -20 -29 -3 -16 10 8 24 22 31 30 7 8 13 10 13 4 0 -16 -43 -60
-76 -77 -57 -30 -206 -46 -281 -31 l-29 6 -2 106 c-2 129 14 201 49 220 27 14
182 11 235 -4z"/>
<path d="M3400 2846 c-5 -14 -17 -51 -26 -83 -9 -32 -39 -116 -66 -188 -27
-71 -58 -152 -68 -180 -60 -167 -104 -281 -111 -289 -15 -17 -9 18 6 38 8 11
15 29 15 40 0 17 -3 15 -14 -9 -56 -120 -62 -145 -31 -145 7 0 25 -19 40 -42
43 -67 81 -93 136 -93 41 0 52 5 92 42 54 50 94 62 215 64 82 2 95 0 115 -19
28 -26 109 -62 152 -68 40 -5 85 27 85 60 0 12 -7 46 -15 76 -9 30 -16 61 -17
68 -1 6 -4 12 -8 12 -4 0 -10 16 -13 34 -3 19 -12 50 -20 68 -8 18 -24 53 -35
78 -11 25 -28 69 -38 98 -10 28 -22 52 -26 52 -5 0 -8 6 -8 14 0 7 -13 42 -29
77 -16 35 -32 79 -36 97 -3 17 -10 32 -15 32 -4 0 -10 10 -13 23 -3 12 -18 54
-35 92 l-29 70 -97 3 c-95 3 -96 2 -106 -22z m220 -496 c15 -5 -26 -8 -108 -9
-87 -1 -131 3 -127 9 7 12 198 12 235 0z"/>
<path d="M5296 2854 c-3 -9 -6 -196 -6 -416 l0 -400 43 -45 c62 -66 112 -96
138 -83 11 6 25 23 31 38 26 64 13 671 -18 830 l-18 92 -82 0 c-65 0 -83 -3
-88 -16z"/>
<path d="M3171 2254 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5064 1845 c-12 -8 -40 -60 -64 -117 -23 -57 -60 -140 -82 -185 -36
-76 -38 -84 -24 -99 9 -8 16 -19 16 -23 0 -5 15 -23 32 -42 l33 -34 147 0
c135 0 148 2 163 20 19 24 15 73 -12 165 -19 67 -58 140 -74 140 -5 0 -9 6 -9
13 0 19 -83 170 -95 174 -6 2 -20 -4 -31 -12z m46 -246 c0 -5 -27 -9 -59 -9
l-60 0 30 32 c16 18 32 41 35 51 5 16 10 12 30 -23 13 -23 24 -46 24 -51z"/>
<path d="M3500 1703 c0 -83 -3 -171 -6 -196 -6 -43 -5 -46 18 -52 14 -4 42
-27 63 -52 94 -113 264 -111 291 4 4 15 11 34 16 40 27 35 3 251 -36 317 -31
52 -73 86 -85 68 -5 -8 -17 -9 -38 -3 -17 5 -74 12 -127 16 l-96 7 0 -149z
m266 -128 c-9 -14 -30 -32 -48 -41 -39 -20 -148 -32 -148 -15 0 6 4 11 9 11 5
0 12 14 16 30 4 17 11 28 16 24 5 -3 9 1 10 8 0 7 4 4 9 -7 5 -11 9 -15 9 -9
1 11 27 21 71 30 8 2 18 -2 21 -8 11 -18 39 10 41 41 1 21 2 20 5 -7 3 -20 -2
-43 -11 -57z"/>
<path d="M3684 1588 c-11 -17 6 -22 22 -7 13 14 13 16 -1 16 -9 0 -18 -4 -21
-9z"/>
<path d="M4604 1843 c-111 -4 -112 -4 -118 -29 -5 -18 -1 -33 11 -48 30 -36
56 -54 70 -49 10 4 13 -22 13 -127 l0 -132 43 -39 c25 -23 52 -38 68 -39 23 0
26 4 25 38 -1 91 15 168 49 235 36 70 44 122 25 171 -8 21 -15 26 -42 24 -18
-1 -83 -4 -144 -5z"/>
<path d="M5804 1836 c-27 -12 -64 -66 -64 -94 0 -6 14 -45 31 -87 17 -42 28
-79 25 -82 -11 -11 24 -33 62 -39 27 -4 50 -1 71 9 34 17 41 9 14 -16 -30 -26
-68 -30 -128 -13 -61 18 -70 16 -78 -13 -4 -15 4 -26 31 -41 20 -12 54 -42 76
-67 21 -24 49 -49 60 -54 12 -6 32 -16 46 -25 14 -8 43 -13 64 -12 34 3 42 8
59 41 17 34 18 45 8 100 -6 34 -12 71 -14 82 -15 87 -53 133 -113 139 -58 5
-184 59 -171 72 5 5 99 -27 114 -38 19 -15 117 -6 125 12 9 21 10 118 1 123
-5 3 -48 7 -98 11 -66 4 -98 2 -121 -8z"/>
<path d="M6545 1838 c-2 -7 -6 -73 -7 -146 -3 -133 -3 -134 -31 -158 -44 -38
-126 -29 -155 16 -6 10 -14 80 -18 154 l-7 136 -44 0 -43 0 0 -97 c0 -54 5
-127 10 -163 8 -57 14 -69 54 -110 24 -25 66 -68 93 -96 63 -65 79 -74 149
-75 52 -2 60 1 80 26 29 37 37 95 24 180 -6 39 -15 131 -20 205 -5 74 -9 136
-9 138 -3 9 -72 0 -76 -10z"/>
<path d="M6850 1842 l-25 -8 -3 -197 c-1 -123 1 -197 7 -197 6 0 18 -17 28
-37 34 -72 133 -148 204 -158 70 -10 141 9 167 44 19 26 22 45 23 135 0 41
-51 191 -82 236 -9 14 -19 43 -23 65 -4 21 -14 52 -22 67 -21 41 -85 80 -70
43 5 -13 2 -14 -15 -4 -26 13 -155 21 -189 11z m188 -143 c-26 -15 -148 -24
-148 -11 0 6 25 13 58 15 31 2 68 4 82 5 21 1 22 0 8 -9z m34 -169 c-30 -25
-82 -34 -129 -23 -37 9 -35 9 36 16 40 4 81 12 90 17 26 15 28 11 3 -10z"/>
<path d="M6522 1650 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6512 1585 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6350 1590 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
